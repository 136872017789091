Body {
  background-color: #553335;
  font-family: "Ubuntu", sans-serif;
}

.App-header {
  color: white;
  padding: 150px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url("coding.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

img.App-logo {
  width: 100px;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

h1,
h2,
h3 {
  text-align: center;
  line-height: 1.5;
}

h1 {
  font-size: 90px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 50px;
}

@media (max-width: 960px) {
  .App-header {
    padding: 20px;
  }

  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 25px;
  }
}
